<template>
  <qtm-dialog-card v-bind="$attrs" persistent title="Move email to another order" width="700">
    <qtm-icon-btn class="close-button" icon="mdi-close" @click="$emit('close')" />
    <div>
      <b class="text--primary">From:</b>
      {{ email.from_address }}
    </div>
    <div>
      <b class="text--primary">To:</b>
      {{ email.to_addresses }}
    </div>
    <div v-if="email.cc_addresses">
      <b class="text--primary">CC:</b>
      {{ email.cc_addresses }}
    </div>
    <div>
      <b class="text--primary">Subject:</b>
      {{ email.subject }}
    </div>
    <div v-if="email.attachments && email.attachments.length">
      <b class="text--primary">Attachments:</b>
      <attachment-links-list :attachments="email.attachments" dense>
        <template v-if="isUnassociated && isInbound" v-slot:action="{ attachment }">
          <v-menu>
            <template v-slot:activator="{ props: activator }">
              <qtm-icon-btn v-bind="activator" icon="mdi-chevron-down" size="small" />
            </template>
            <v-list class="qtm-border">
              <v-list-item @click="createDraftPo(attachment)">
                <v-list-item-title>Create a Draft PO</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </attachment-links-list>
    </div>
    <div>
      <b class="text--primary">Body:</b>
      <div class="email-body-continer">
        <safe-html-renderer :html="email.body" />
      </div>
    </div>
    <br>
    <b class="text--primary">Move email to:</b>
    <v-row>
      <v-col cols="9">
        <order-search
          density="compact"
          max-width="485"
          @update:model-value="backendOrderSelected"
        />
      </v-col>
      <v-col cols="3">
        <qtm-btn :loading="loading" @click="moveEmail">
          Move
          <v-icon location="right">
            mdi-send
          </v-icon>
        </qtm-btn>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="sendToSupport">
        <v-icon>mdi-face-agent</v-icon>
        Send to support
      </qtm-btn>
      <order-from-email-btn v-if="!email.rfq" :email="email" :loading="loading" @order-created="orderCreated" />
      <multi-action-btn v-if="!email.is_general_inbox" :actions="generalInboxActions" free-width />
    </template>
  </qtm-dialog-card>

  <qtm-dialog-card v-model="showMoveRelatedEmailsModal" persistent title="Move Multiple Emails" width="700">
    <qtm-icon-btn class="close-button" icon="mdi-close" @click="showMoveRelatedEmailsModal = false" />

    <v-row>
      <v-col>
        There {{ relatedEmails.length === 1 ? 'is' : 'are' }} {{ relatedEmails.length }}
        related unassociated email{{ relatedEmails.length === 1 ? '' : 's' }} that can also be moved.
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="showMoveRelatedEmailsModal = false">
        Cancel
      </qtm-btn>
      <qtm-btn :loading="loading" secondary @click="moveCurrentEmailOnly">
        Move this email only
      </qtm-btn>
      <qtm-btn :loading="loading" secondary @click="moveAllRelatedEmails">
        Move all
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import AttachmentLinksList from '@/components/attachment-links-list.vue'
import MultiActionBtn from '@/components/multi-action-btn.vue'
import OrderSearch from '@/components/search/order-search.vue'
import OrderFromEmailBtn from '@/components/admin/emails/order-from-email-btn.vue'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer.vue'
import type { Attachment, Order } from '@quotetome/materials-api'
import { useRouter } from 'vue-router'
import { SEARCH_MAX_LENGTH } from '@/constants'

interface Props {
  email?: any,
  maxlength?: number | string,
  openOrderAfterMoving?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  email: null,
  maxlength: SEARCH_MAX_LENGTH,
  openOrderAfterMoving: false
})

const emit = defineEmits(['close', 'update:email'])

const loading = ref(false)
const order = ref(null)
const relatedEmails = ref([])
const showMoveRelatedEmailsModal = ref(false)

const generalInboxActions = computed(() => {
  return [
    { label: 'General Inbox', onClick: moveToGeneralInbox },
    { label: 'Move & Mark as Read', onClick: () => moveToGeneralInbox({ markAsRead: true }) },
  ]
})
const isInbound = computed(() => props.email?.email_type === 'inbound')
const isUnassociated = computed(() => !props.email?.rfq)

watch(() => props.email, () => {
  order.value = null
})

const adminStore = useAdminStore()
const activateOrder = adminStore.activateOrder
const updateOrder = adminStore.updateOrder
const removeUnassociatedEmail = adminStore.removeUnassociatedEmail
const orderStore = useOrdersStore()
const setOrderFromEmail = orderStore.setOrderFromEmail

const { $api, $error, $toast } = useNuxtApp()
const router = useRouter()

const createDraftPo = async (attachment: Attachment) => {
  try {
    const [owner] = await $api.v1.users.list({ email: props.email.from_address })

    setOrderFromEmail({
      owner,
      quote_attachment: attachment,
      unassociated_email: props.email.id,
    })
    emit('close')
    router.push({ name: 'purchase-orders-new' })
  }
  catch (error) {
    $error.report(error)
  }
}

const moveToGeneralInbox = async ({ markAsRead = false } = {}) => {
  loading.value = true

  const data = { is_general_inbox: true, rfq: null }

  if (markAsRead) {
    data.tracking_status = 'opened'
  }

  try {
    const email = await $api.v1.notifications.email.patch(props.email.id, data)

    if (markAsRead) {
      await $api.v1.notifications.email.markAsRead(props.email.id)
    }

    emit('update:email', email)
    emit('close')

    if (props.openOrderAfterMoving) {
      removeUnassociatedEmail(props.email)
    }
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const sendToSupport = async () => {
  loading.value = true
  try {
    await $api.v1.notifications.email.moveToSupport(props.email.id, {})
    emit('close')
    if (props.openOrderAfterMoving) {
      removeUnassociatedEmail(props.email)
    }
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const moveEmail = async () => {
  if (!order.value) {
    $toast.warning('Please choose an order to move the email to.')
    return
  }
  if (props.email.rfq === order.value) {
    $toast.warning('This email is currently associated to that order, please choose another one.')
    return
  }

  loading.value = true

  relatedEmails.value = await $api.v1.notifications.email.getUnassociatedReferencedEmails(props.email.id)

  if (relatedEmails.value.length) {
    showMoveRelatedEmailsModal.value = true
  }
  else {
    await moveSingleEmail(props.email.id)
    emit('close')
    if (props.openOrderAfterMoving) {
      activateOrder({ id: order.value })
    }
  }
  loading.value = false
}

const moveCurrentEmailOnly = async () => {
  loading.value = true
  await moveSingleEmail(props.email.id)
  emit('close')
  if (props.openOrderAfterMoving) {
    activateOrder({ id: order.value })
  }
  loading.value = false
}

const moveAllRelatedEmails = async () => {
  loading.value = true
  try {
    const mainEmailPromise = moveSingleEmail(props.email.id)
    await Promise.all(relatedEmails.value.map(email => moveSingleEmail(email.id)))
    await mainEmailPromise
    emit('close')
    if (props.openOrderAfterMoving) {
      activateOrder({ id: order.value })
    }
    emit('close')
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const moveSingleEmail = async (emailId: number) => {
  try {
    const email = await $api.v1.notifications.email.patch(
      emailId,
      { rfq: order.value, is_general_inbox: false }
    )
    if (emailId === props.email.id) {
      emit('update:email', email)
    }
    removeUnassociatedEmail(email)
    return email
  }
  catch (error) {
    $toast.error(error)
    $error.report(error)
    return null
  }
}

const orderCreated = (order: Order) => {
  removeUnassociatedEmail(props.email)
  updateOrder(order)
  activateOrder(order)
  emit('close')
}

const backendOrderSelected = (o: Order) => {
  order.value = o?.id ?? null
}
</script>
<style scoped>
  .move-email-title {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .email-body-continer {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
