<template>
  <qtm-dialog-card
    :model-value="show"
    persistent
    scrollable
    width="1200px"
    @update:model-value="$emit('update:show', $event)"
  >
    <template v-slot:title>
      <qtm-icon-btn icon="mdi-close" class="close-button" color="interactive" @click="close()" />
      {{ modalTitle }}
    </template>
    <div
      class="white my-n6 mx-n6 pa-6"
      @dragover="expandFileDropper"
      @dragleave="shrinkFileDropper"
      @drop="shrinkFileDropper"
    >
      <qtm-autocomplete
        v-if="hasToShowFromAddressInput"
        v-model="selectedCompany"
        class="mt-3"
        dense
        :item-title="companyNameAndEmail"
        :items="emailReadyCompanies"
        label="From"
      />
      <email-address-combobox v-model="to" :addresses="relatedAddresses" label="To" />
      <v-row>
        <v-col>
          <email-address-combobox v-model="cc" :addresses="relatedAddresses" label="CC" />
        </v-col>
        <v-col>
          <email-address-combobox v-model="bcc" :addresses="relatedAddresses" label="BCC" />
          <v-col />
        </v-col>
      </v-row>
      <qtm-text-field v-model="subject" dense :disabled="!!repliedEmail" label="Subject" />
      <rich-text-editor ref="editor" v-model="body" placeholder="Write email body here..." @images="addInlineImages">
        <template v-if="order" v-slot:actions-right>
          <div class="text-mid-dark-grey qtm-body">
            Copy Order URL
            <copy-text hidden :text="orderUrl" />
          </div>
        </template>
      </rich-text-editor>
      <ul class="attached-files">
        <li v-for="uploadedFile in uploadedFileList" :key="uploadedFile.id">
          <v-chip closable label size="small" variant="flat" @click:close="removeAttachment(uploadedFile.id)">
            <v-icon size="small">
              mdi-paperclip
            </v-icon>
            {{ uploadedFile.name }}
          </v-chip>
        </li>
      </ul>
      <v-row align="end">
        <v-col>
          <file-dropper
            v-model="fileInputList"
            class="mt-5"
            multiple
            :expanded="fileDropperExpanded"
            @change="uploadFile"
          />
          <qtm-select
            v-if="allAttachments.length"
            v-model="attachments"
            dense
            hint="Attachments already uploaded to the order."
            item-title="name"
            :items="allAttachments"
            :menu-props="{ maxHeight: '400' }"
            multiple
            persistent-hint
            placeholder="Use current Order Attachments"
          >
            <template v-slot:selection="{ item }">
              <v-chip variant="flat">
                <span>{{ item.raw.name }}&nbsp;</span>
                <a class="text-decoration-none" :href="item.raw.url" target="_blank" @click="open(item.raw.url)">
                  <v-icon size="small">mdi-eye</v-icon>
                </a>
              </v-chip>
            </template>
          </qtm-select>
          <qtm-select
            v-if="repliedEmail && repliedEmail.attachments.length"
            v-model="forwardedAttachments"
            dense
            hint="Attachments from the forwarded email."
            item-title="name"
            :items="repliedEmail.attachments"
            :menu-props="{ maxHeight: '400' }"
            multiple
            persistent-hint
            placeholder="Forwarded Attachments"
          >
            <template v-slot:selection="{ item }">
              <v-chip variant="flat">
                <span>{{ item.raw.name }}&nbsp;</span>
                <a class="text-decoration-none" :href="item.raw.url" target="_blank" @click="open(item.raw.url)">
                  <v-icon size="small">mdi-eye</v-icon>
                </a>
              </v-chip>
            </template>
          </qtm-select>
        </v-col>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn v-if="repliedEmail" class="mr-6" tertiary @click="toggleGlobalEmailViewer">
        {{ globalEmailViewer.show?"Hide":"View" }} the original email
      </qtm-btn>
      <qtm-btn tertiary @click="close()">
        Cancel
      </qtm-btn>
      <qtm-btn size="large" :loading="loading" @click="sendEmail()">
        Send
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import CopyText from '@/components/copy-text.vue'
import EmailAddressCombobox from '@/components/emails/email-address-combobox.vue'
import FileDropper from '@/components/file-dropper/file-dropper.vue'
import RichTextEditor from '@/components/emails/rich-text-editor.vue'

export default {
  name: 'send-email',
  components: { CopyText, EmailAddressCombobox, FileDropper, RichTextEditor },
  props: {
    order: {
      type: Object,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    },
    companies: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      required: true
    },
    repliedEmail: {
      type: Object,
      required: false,
      default: null
    },
    replyType: {
      type: String,
      required: false,
      default: 'single'
    }
  },
  emits: ['close', 'update:show'],
  data() {
    return {
      loading: false,
      relatedAddresses: [],
      to: [],
      cc: [],
      bcc: [],
      attachments: [],
      forwardedAttachments: [],
      subject: '',
      body: '',
      fileInputList: [],
      uploadedFileList: [],
      selectedCompany: null,
      internalCompanyList: [],
      fileDropperExpanded: false,
      fileDropperExpandedTimeout: null
    }
  },
  computed: {
    ...mapState(useAdminStore, ['globalEmailViewer']),
    allAttachments() {
      if (!this.order || !this.order.pos) { return [] }
      const reducer = (result, po) => [...result, ...po.attachments.po_attachments, ...po.attachments.quote_attachments]
      return this.order.pos.reduce(reducer, this.order.attachments)
    },
    emailReadyCompanies() {
      return this.companies.length ? this.companies : this.internalCompanyList
    },
    hasToShowFromAddressInput() {
      return !this.companyId && (!this.order || !this.order.jobsite)
    },
    modalTitle() {
      if (this.repliedEmail) {
        if (this.replyType === 'forward') {
          return 'Forward email'
        }
        return 'Reply email'
      }
      return 'Send new email'
    },
    orderUrl() {
      if (this.order) {
        return window.location.origin + this.$router.resolve({
          name: 'orders-id',
          params: { id: this.order.id },
        }).href
      }

      return ''
    },
    subjectPrefix() {
      if (!this.repliedEmail) {
        return ''
      }
      if (this.replyType === 'forward') {
        return 'Fw: '
      }
      return 'Re: '
    }
  },
  watch: {
    repliedEmail(newRepliedEmail) {
      if (newRepliedEmail) { this.refreshAddresses() }
    },
    replyType(newReplyType, oldReplyType) {
      if (newReplyType !== oldReplyType) { this.refreshAddresses() }
    }
  },
  mounted() {
    this.fetchRelatedAddresses()
    this.refreshAddresses()
    this.loadCompaniesIfNeeded()
  },
  methods: {
    ...mapActions(useAdminStore, ['showGlobalEmailViewer', 'hideGlobalEmailViewer']),
    addInlineImages(images) {
      images.forEach(image => {
        const formData = new FormData()

        formData.append('file', image, image.fileName)
        this.$api.v1.attachments.create(formData, null)
          .then(this.$refs.editor.addImage)
          .catch(this.$error.report)
      })
    },
    companyNameAndEmail(item) {
      let company = item
      if (typeof company === 'number') {
        company = this.emailReadyCompanies.find(m => m.id === item)
      }
      if (company?.name && company?.purchasing_email) {
        return `${company.name} (${company.purchasing_email})`
      }
      return ''
    },
    toggleGlobalEmailViewer() {
      if (this.globalEmailViewer?.show) {
        this.hideGlobalEmailViewer()
      }
      else {
        this.showGlobalEmailViewer(this.repliedEmail)
      }
    },
    shrinkFileDropper() {
      this.fileDropperExpandedTimeout = setTimeout(() => {
        this.fileDropperExpanded = false
      }, 300)
    },
    expandFileDropper() {
      clearTimeout(this.fileDropperExpandedTimeout)
      this.fileDropperExpanded = true
    },
    removeAttachment(id) {
      this.uploadedFileList = this.uploadedFileList.filter(file => file.id !== id)
      this.$api.v1.attachments.delete(id)
    },
    uploadFile() {
      this.fileInputList.forEach((image) => {
        try {
          const formData = new FormData()
          formData.append('file', image, image.fileName)

          this.$api.v1.attachments
            .create(formData, null)
            .then(response => {
              this.fileInputList = this.fileInputList.filter(item => item !== image)
              this.uploadedFileList.push(response)
            })
        }
        catch (error) {
          this.$error.report(error)
        }
      })
    },
    open(link) {
      window.open(link)
    },
    refreshAddresses() {
      if (!this.repliedEmail) { return [] }

      this.subject = `${this.subjectPrefix}${this.repliedEmail.subject}`
      if (this.replyType !== 'forward') {
        if (this.repliedEmail.email_type === 'inbound') {
          if (this.replyType === 'single') {
            this.to = [this.repliedEmail.from_address]
          }
          else {
            this.to = [this.repliedEmail.from_address, ...this.repliedEmail.to_addresses.split(',')]
            if (this.repliedEmail.cc_addresses?.length) {
              this.cc = this.repliedEmail.cc_addresses.split(',')
            }
          }
        }
        else if (this.replyType === 'single') {
          this.to = this.repliedEmail.to_addresses.split(',')
        }
        else {
          this.to = this.repliedEmail.to_addresses.split(',')
          if (this.repliedEmail.cc_addresses?.length) {
            this.cc = this.repliedEmail.cc_addresses.split(',')
          }
        }
      }
      else {
        this.forwardedAttachments = this.repliedEmail.attachments.map(a => a.id)
      }
      return {}
    },
    clean() {
      this.to = []
      this.cc = []
      this.bcc = []
      this.attachments = []
      this.subject = ''
      this.body = ''
      this.uploadedFileList = []
    },
    close() {
      this.clean()
      this.$emit('close')
    },
    filterEmails(item, queryText) {
      const text = queryText.toLowerCase()
      return item.name.toLowerCase().includes(text) || item.email.toLowerCase().includes(text)
    },
    async fetchRelatedAddresses() {
      if (!this.order) {
        return
      }
      this.loading = true
      try {
        this.relatedAddresses = await this.$api.v1.notifications.email.getRelatedAddresses(this.order)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async sendEmail() {
      this.loading = true

      const data = {
        rfq: this.order ? this.order.id : null,
        company: this.companyId || this.selectedCompany,
        to_addresses: this.to.join(','),
        cc_addresses: this.cc.join(','),
        bcc_addresses: this.bcc.join(','),
        subject: this.subject,
        body: this.body,
        attachments: this.attachments.concat(this.forwardedAttachments).join(','),
        custom_attachments: this.uploadedFileList.map(elem => elem.id).join(','),
      }

      if (this.repliedEmail) {
        data.in_reply_to_id = this.repliedEmail.id
        data.reply_type = this.replyType
      }
      try {
        await this.$api.v1.notifications.email.send(data)
        this.close()
      }
      catch (e) {
        this.$error.report(e)
      }
      this.loading = false
    },
    async loadCompaniesIfNeeded() {
      if (this.hasToShowFromAddressInput && !this.companies.length) {
        try {
          const companies = await this.$api.v1.companies.list()
          this.internalCompanyList = companies.filter(company => company.email_ready)
        }
        catch (error) {
          this.$error.report(error)
          this.$toast.error('Error loading company list.')
        }
      }
    },
  },
}
</script>
<style scoped>
  .col {
    padding-bottom: 5px;
  }
  .attached-files {
    padding-left: 0;
  }
  .attached-files li {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 3px;
  }
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
</style>
